function LinksController($scope, $document, $log, jobEvents, jobTracker) {
  /* @public property*/

  //TODO: Handle counts based on list of job ids
  // To allow us to handle cases where a job is started 
  // Before the interface knows about it via submission listener
  var vm = this;
  vm.failedJobsCount = 0;
  vm.completedJobsCount = 0;
  vm.incompleteJobsCount = 5;
  vm.incompleteInProgress = 0;
  vm.jobTracker = jobTracker;

  vm.clearIncompleteCount = () => {
    vm.incompleteJobsCount = 0;
    vm.incompleteInProgress = 0;
  };

  $scope.$on(jobEvents.eventPrefix + jobEvents.events.clear, () => {
    vm.incompleteJobsCount = 0;
    vm.completedJobsCount = 0;
    vm.failedJobsCount = 0;
    vm.incompleteInProgress = 0;
  });

  $scope.$on(jobEvents.eventPrefix + jobEvents.events.annotation.started, () => {
    vm.incompleteInProgress = 0; // to allow the navbar to show the job count
    vm.incompleteJobsCount++;
  });

  $scope.$on(jobEvents.eventPrefix + jobEvents.events.annotation.failed, () => {
    vm.failedJobsCount++;

    if (vm.incompleteJobsCount) {
      vm.incompleteJobsCount--;
    }

    if (vm.incompleteInProgress) {
      vm.incompleteInProgress--;
    }
  });

  $scope.$on(jobEvents.eventPrefix + jobEvents.events.annotation.completed, () => {
    vm.completedJobsCount++;

    if (vm.incompleteJobsCount) {
      vm.incompleteJobsCount--;
    }

    if (vm.incompleteInProgress) {
      vm.incompleteInProgress--;
    }
  });
}

angular.module('sq.navbar.links.component', ['sq.jobs.events.service', 'sq.jobs.tracker.service'])
  .component('navbarLinks', {
    controller: LinksController,
    templateUrl: 'navbar/links/navbar.links.tpl.html',
  });
